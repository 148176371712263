import React, { Component } from 'react';

import PropTypes from 'prop-types';

import BGArrowPointerBottomLeft from '../../assets/images/bg-arrow-pointer-bottom-left.inline.svg';
import BGArrowPointerBottomRight from '../../assets/images/bg-arrow-pointer-bottom-right.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-arrow-pointer-top-left.inline.svg';
import BGArrowPointerTopRight from '../../assets/images/bg-arrow-pointer-top-right.inline.svg';

import './ShapeBackground.scss';

let classNames = require('classnames');

class ShapeBackground extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { top, bottom, color, bottomInfinite, background, children } = this.props;
    return (
      <div className={classNames('shaped_background', color, top ? 'top_' + top : 'no_top', bottom ? 'bottom_' + bottom : 'no_bottom', bottomInfinite ? 'bottom_infinite' : null)}>
        {top === 'left' ? (
          <div className={classNames('bg_arrow_pointer_top', background ? background : null)}>
            <BGArrowPointerTopLeft />
          </div>
        ) : top === 'right' ? (
          <div className={classNames('bg_arrow_pointer_top', background ? background : null)}>
            <BGArrowPointerTopRight />
          </div>
        ) : (null)}
        <div className="center_content">
          {children}
        </div>
        {bottom === 'left' ? (
          <div className={classNames('bg_arrow_pointer_bottom', background ? background : null)}>
            <BGArrowPointerBottomLeft />
          </div>
        ) : bottom === 'right' ? (
          <div className={classNames('bg_arrow_pointer_bottom', background ? background : null)}>
            <BGArrowPointerBottomRight />
          </div>
        ) : (null)}

      </div>
    );
  }
}

ShapeBackground.defaultProps = {
  color: 'bleu'
};

export default ShapeBackground;
