import * as React from 'react';

import './TitlePage.scss';
import { RoundedArrowBorder } from '../../Atoms/Icons/Icons';

let classNames = require('classnames');

export const TitlePage = ({ title, description, color }) => {
  const description_p = description && description.indexOf('<p>') > -1 ? description : '<p>' + description + '<p>';
  return (
    <div className={classNames('title_intro_page_component', color)}>
      <div className="title">
        <RoundedArrowBorder color={color} />
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
      {
        description && (
          <div dangerouslySetInnerHTML={{ __html: description_p }}></div>
        )
      }
    </div>
  );
};

TitlePage.defaultProps = {
  title: 'Title Page',
  color: 'color_bleu'
};

export default TitlePage;
