import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import VisuelTextOverlappedBlock from '../../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, removeHtmlTag } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const OurStrategyPage = ({ data }) => {
  const intl = useIntl();
  const imagesArray = data?.allImages?.edges;
  const ourStrategyData = data?.ourStrategyData?.edges[0]?.node;

  const [innerNavList, setInnerNavList] = useState([]);

  let innerNav = [];

  useEffect(() => {
    setInnerNavList(innerNav);
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={ourStrategyData?.title}
        description={ourStrategyData?.body?.processed}
      />
      <div className={classNames('page_template', 'our_strategy_page')}>
        <ScrollPageNav
          data={innerNavList}
        />
        <PageBanner visuel={getImage(imagesArray, ourStrategyData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                // grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: intl.formatMessage({ id: 'group.label' }), url: '' },
                currentPage: {
                  title: ourStrategyData?.title, url: ''
                },
                locale: ourStrategyData.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={ourStrategyData?.title}
              description={ourStrategyData?.body?.processed}
            />
          </div>
        </PageBanner>

        {ourStrategyData.relationships?.field_blocs?.map((block, i) => {
          if (block.__typename === 'block_content__block_texte') {
            innerNav.push({
              title: '0' + (i + 1) + '. ' + block.field_title?.processed,
              section: 'scroll_to_' + i
            });
            switch (block.field_backgroud_color) {
              case 'white':

                return (
                  <section className={classNames('section_content', 'scroll_to_' + i, 'section_developement')} key={i}>
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        description={removeHtmlTag(block.field_subtitle?.processed)}
                        type="line"
                      />
                      {block.relationships?.field_section?.map((section, j) => {
                        return (
                          <TextVisuelTwoCol
                            key={j}
                            orderInverse={section?.field_alignment === 'right' ? true : false}
                            extremeLeft={(section?.field_alignment === 'left' && section?.field_no_padding) ? true : false}
                            extremeRight={true}
                            visuel={getImage(imagesArray, section?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                            title={section.relationships?.field_bloc_texte[0]?.field_title?.processed}
                            text={section.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}
                          />
                        );
                      })}

                    </div>
                  </section>
                );

              case 'ice':

                return (
                  <section className={classNames('section_content', 'scroll_to_' + i, 'section_climate_issues')} key={i}>
                    <ShapeBackground
                      top="left"
                      color="catskill_white"
                      bottomInfinite={true}
                    >
                      <div className="full_page_wrapper">
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            description={removeHtmlTag(block.field_subtitle?.processed)}
                            type="arrow"
                            h2color='color_dark_bleu'
                          />
                        </div>
                        <VisuelTextOverlappedBlock
                          extremeLeft={true}
                          visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                          bgColor='color_dark_bleu'
                          alt=""
                          text={block?.body?.processed}
                        />
                        <div className="wrapper_page_xs">
                          <div className="free_highlight_text" dangerouslySetInnerHTML={{ __html: block?.field_bloc_text_1?.processed }}>

                          </div>
                          {block.relationships?.field_section?.map((section, j) => {
                            return (
                              <TextVisuelTwoCol
                                key={j}
                                orderInverse={section?.field_alignment === 'right' ? true : false}
                                extremeLeft={(section?.field_alignment === 'left' && section?.field_no_padding) ? true : false}
                                extremeRight={true}
                                visuel={getImage(imagesArray, section?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                                title={section.relationships?.field_bloc_texte[0]?.field_title?.processed}
                                text={section.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'navy':

                return (
                  <section className={classNames('section_content', 'scroll_to_' + i, 'section_force_astuce')} key={i}>
                    <ShapeBackground
                      top="left"
                      bottomInfinite={true}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          description={removeHtmlTag(block.field_subtitle?.processed)}
                          type="arrow"
                          h2color="white"
                        />
                      </div>
                      <VisuelTextOverlappedBlock
                        visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                        bgColor='color_sky_bleu'
                        extremeLeft={true}
                        alt=""
                        text={block?.body?.processed}
                      />
                      <div className="wrapper_page_xs">
                        {block.relationships?.field_section?.map((section, j) => {
                          return (

                            <TextVisuelTwoCol
                              key={j}
                              orderInverse={section?.field_alignment === 'right' ? true : false}
                              visuel={getImage(imagesArray, section?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                              colorTitle="color_white"
                              colorDescription="color_white"
                              title={section.relationships?.field_bloc_texte[0]?.field_title?.processed}
                              titleBackground={true}
                              extactHalf={true}
                              alignTop={true}
                              text={section.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}
                            />
                          );
                        })}

                      </div>
                    </ShapeBackground>
                  </section>
                );
            }
          }
        })}

      </div>
    </Layout >
  );
};

export const query = graphql`
query OurStrategyTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  ourStrategyData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        path {
          alias
          langcode
        }
        field_type
        relationships {
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__block_texte {
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_bloc_text_1 {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_section {
                    field_no_padding
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_title {
                          processed
                        }
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                    field_alignment
                  }
                }
                body {
                  processed
                }
                field_backgroud_color
              }
            }
          }
          field_image {
            drupal_internal__mid
          }
        }
        langcode
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 332, height: 332) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  file(relativePath: {eq: "slider-image.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default OurStrategyPage;
