import * as React from 'react';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import './VisuelTextOverlappedBlock.scss';
import DefaultImage from '../../../assets/images/history.png';

let classNames = require('classnames');

const VisuelTextOverlappedBlock = ({ extremeLeft, extremeRight, visuel, alt, title, text, bgColor }) => {
  return (
    <div className={classNames('visuel_text_overlapped_block')}>
      <div className={classNames({ 'wrapper_page': extremeLeft || extremeRight })}>
        <div className={classNames({ 'cross_wrapper_extreme_left': extremeLeft }, { 'cross_wrapper_extreme_right': extremeRight })}>
          <div className="text_visuel">
            <div className="visuel">
              {visuel &&
                <>
                  <Img
                    style={{ height: '100%', width: '100%' }}
                    imgStyle={{ objectFit: 'cover' }}
                    className="only_mobile"
                    fluid={{ ...visuel.fluid, aspectRatio: "0.91627907" }}
                    alt={alt}
                  />
                  <Img
                    style={{ height: '100%', width: '100%' }}
                    imgStyle={{ objectFit: 'cover' }}
                    fluid={{ ...visuel.fluid, aspectRatio: "1.78372093" }}
                    className="only_tablet_desktop"
                    alt={alt}
                  />
                </>
              }
              {!visuel && DefaultImage &&
                <img src={DefaultImage} alt={alt} />
              }
            </div>
            <div className="text_container">
              <div className={classNames('text', bgColor)} dangerouslySetInnerHTML={{ __html: text }}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VisuelTextOverlappedBlock.defaultProps = {
  title: 'Default Title',
  text: 'Default text',
  alt: '',
  bgColor: 'color_dark_bleu'
};

VisuelTextOverlappedBlock.propTypes = {
  visuel: PropTypes.object,
  title: PropTypes.string,
  text: PropTypes.string

};

export default VisuelTextOverlappedBlock;
