import * as React from "react";

import { ScrollIconSVG } from '../../Atoms/Icons/Icons'

import './ScrollIcon.scss';


const ScrollIcon = ({ bannerHeight }) => {

  const onClickScrollIcon = (e) => {
    e.preventDefault();
    // document.querySelectorAll('.section_content')[0].scrollIntoView(
    //   {
    //     block: "start",
    //     inline: "start",
    //     behavior: "smooth",
    //     alignToTop: false
    //   }
    // );
    const firstElement = document.querySelectorAll('.section_content')[0] || document.querySelector('.page_content');
    if (firstElement) {
      window.scrollTo({ top: firstElement.offsetTop, behavior: 'smooth' });
    } else {
      alert('Nothing to scroll');
    }
  }

  return (
    <a href="#" className="scroll_icon" onClick={(e) => onClickScrollIcon(e)}>
      <span>Scroll</span>
      <ScrollIconSVG color="color_white" />
    </a>
  );
};


export default ScrollIcon;