import React, { useState, useEffect } from 'react';

import { getOffset } from '../../Utils/Utils';

import './ScrollPageNav.scss';
let classNames = require('classnames');
const ScrollPageNav = ({ data, scrollTo }) => {
  const [headerHeight, setHeaderHeight] = useState(false);
  const [windowHeight, setWindowHeight] = useState(false);
  const [sectionsList, setSectionsList] = useState([]);
  const [sectionListUpdated, setSectionListUpdated] = useState(false);
  const [myData, setMyData] = useState([]);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  useEffect(() => {
    if (window.innerWidth > 1023 && myData.length > 0) {
      setHeaderHeight(document.querySelector('header.header').offsetHeight);

      setWindowHeight(window.innerHeight);
      const sections_array = [];
      myData.forEach((block, index) => {
        const section = document.querySelector('.' + block.section);
        const title = section ? section.querySelector('.title_section') : null;

        let scrollEl = title || section;
        if (block.scrollEl === 'section') { scrollEl = section }

        const section_object = {
          el: section,
          top: getOffset(scrollEl).top,
          height: section?.offsetHeight,
          el_scrolled: false
        };
        sections_array.push(section_object);
      });
      setSectionsList(sections_array);
      setSectionListUpdated(true);
    }
  }, [myData]);

  useEffect(() => {
    setTimeout(() => {
      if (sectionsList.length > 0) {
        onPageScroll();
        window.addEventListener('scroll', onPageScroll);
        scrollTo && document?.querySelector(`a[data-section=${scrollTo}]`)?.click();
      }
    }, 1000);

    return () => window.removeEventListener('scroll', onPageScroll);
  }, [sectionListUpdated]);

  const onPageScroll = () => {
    setHeaderHeight(document.querySelector('header.header').offsetHeight);
    const sections_array = [];
    sectionsList.forEach((section, index) => {
      const section_object = {
        el: section.el,
        top: section.top,
        height: section.height,
        el_scrolled: section.el_scrolled
      };
      if (section.top - windowHeight / 2 < window.pageYOffset) {
        section_object.el_scrolled = true;
      } else {
        section_object.el_scrolled = false;
      }
      sections_array.push(section_object);
    });
    setSectionsList(sections_array);
  };

  const onClickAnchorNav = (e, idx) => {
    e.preventDefault();
    window.scrollTo({ top: sectionsList[idx].top - headerHeight - 100, behavior: 'smooth' });
  };

  return (
    <div className="scroll_page_nav" style={{ top: headerHeight }}>
      <ul>
        {data?.map((anchor_nav_item, i) => (
          <li key={i} className={classNames({ 'hide': anchor_nav_item.title === 'hide' })}>

            <div className={classNames('scroll_pos', { 'is_active': sectionsList[i]?.el_scrolled })}></div>
            <a href='#' data-section={anchor_nav_item.section} className={'on-scroll-' + i} onClick={(e) => onClickAnchorNav(e, i)}>{anchor_nav_item.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScrollPageNav;
